<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
         <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{ $t('elearning_tim.course_evaluation') }}
              <slot v-if="$route.query.id">
                {{ $t('globalTrans.update') }}
              </slot>
              <slot v-else>
                {{ $t('globalTrans.entry') }}
              </slot>
            </h4>
          </template>
          <template v-slot:headerAction>
            <router-link to="course-evaluation" :class="'btn btn-success text-light'">{{ $t('elearning_tim.course_evaluation') }} {{ $t('globalTrans.list') }}</router-link>
          </template>
          <template v-slot:body>
            <b-overlay :show="loading">
            <b-row class="text-black mb-4">
              <b-col lg="4">
                <span><strong>{{ $t('elearning_config.training_type') }}:</strong> {{ currentLocale === 'en' ? evaluationObj.training_type : evaluationObj.training_type_bn }}</span><br>
                <span><strong>{{ $t('elearning_config.training_category') }}:</strong> {{ currentLocale === 'en' ? evaluationObj.training_category : evaluationObj.training_category_bn }}</span><br>
                <span><strong>{{ $t('elearning_config.training_title') }}:</strong> {{ currentLocale === 'en' ? evaluationObj.training_title : evaluationObj.training_title_bn }}</span><br>
                <span><strong>{{ $t('elearning_tim.course_name') }}:</strong> {{ currentLocale === 'en' ? evaluationObj.course_name : evaluationObj.course_name_bn }}</span>
              </b-col>
              <b-col lg="4" offset="4">
                <span><strong>{{ $t('elearning_tim.exam_date') }}:</strong> {{ formData.exam_date | dateFormat }}</span><br>
                <span><strong>{{ $t('elearning_tim.exam_time_start') }}:</strong> {{ formData.exam_time_start }}</span><br>
                <span><strong>{{ $t('elearning_tim.exam_time_end') }}:</strong> {{ formData.exam_time_end }}</span><br>
                <span><strong>{{ $t('elearning_tim.total_marks') }}:</strong> {{ $n(evaluationObj.total_marks) }}</span>
              </b-col>
            </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row v-for="(evaluationQuestion, questionIndex) in evaluationData.evaluation_questions" :key="questionIndex">
                        <div class="col-md-12">
                          <fieldset class="text-black">
                            <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tim.question')}} {{ $n(questionIndex+1) }}</legend>
                            <div class="px-3">
                              <b-row>
                                <b-col lg="8" sm="8">
                                  <div class="question-answer mt-2">
                                    <ValidationProvider name="Written Answer"  :vid="'written_answer_' + (questionIndex+1)" rules="">
                                      <b-form-group
                                          class="row"
                                          label-cols-sm="12"
                                          :label-for="'written_answer_' + (questionIndex+1)"
                                          slot-scope="{ valid, errors }"
                                      >
                                        <template v-slot:label>
                                          <strong>{{ $t('elearning_tim.question') }}</strong> : {{ currentLocale === 'en' ? evaluationQuestion.question : evaluationQuestion.question_bn }}
                                        </template>
                                        <template v-if="evaluationQuestion.question_type == 2">
                                          <b-form-textarea
                                              :id="'written_answer_' + (questionIndex+1)"
                                              v-model="formData.course_evaluation_answers[questionIndex].written_answer"
                                              :state="errors[0] ? false : (valid ? true : null)"
                                              :placeholder="$t('elearning_tim.answer')"
                                              :disabled="id"
                                          ></b-form-textarea>
                                        </template>
                                        <template v-else>
                                          <b-row>
                                            <b-col lg="6" v-for="(evaluationOption, optionIndex) in evaluationQuestion.evaluation_options" :key="optionIndex">
                                              <template v-if="hasMultipleOptions(evaluationQuestion)">
                                                <b-form-checkbox v-model="formData.course_evaluation_answers[questionIndex].mcq_answer_multiple" :value="evaluationOption.id" v-if="evaluationOption.option_en || evaluationOption.option_bn || evaluationOption.option_attachment" :disabled="id">
                                                  <template v-if="evaluationOption.option_en || evaluationOption.option_bn">{{ currentLocale === 'en' ?  evaluationOption.option_en : evaluationOption.option_bn }}</template>
                                                  <template v-else>
                                                    <a v-if="evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                                  </template>
                                                </b-form-checkbox>
                                              </template>
                                              <template v-else>
                                                <b-form-radio v-model="formData.course_evaluation_answers[questionIndex].mcq_answer" :value="evaluationOption.id" v-if="evaluationOption.option_en || evaluationOption.option_bn || evaluationOption.option_attachment" :disabled="id">
                                                  <template v-if="evaluationOption.option_en || evaluationOption.option_bn">{{ currentLocale === 'en' ?  evaluationOption.option_en : evaluationOption.option_bn }}</template>
                                                  <template v-else>
                                                    <a v-if="evaluationOption.option_attachment" class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationOption.option_attachment" download><i class="fas fa-download"></i> {{$t('globalTrans.view_download')}}</a>
                                                  </template>
                                                </b-form-radio>
                                              </template>
                                            </b-col>
                                          </b-row>
                                        </template>
                                        <div class="invalid-feedback">
                                          {{ errors[0] }}
                                        </div>
                                      </b-form-group>
                                    </ValidationProvider>
                                  </div>
                                </b-col>
                                <b-col lg="4" sm="4" class="text-right">
                                  <div class="mb-2"><strong>{{ $t('elearning_tim.marks') }}: </strong> {{ $n(evaluationQuestion.marks) }}</div>
                                  <p v-if="evaluationQuestion.description || evaluationQuestion.description_bn">{{ currentLocale === 'en' ? evaluationQuestion.description : evaluationQuestion.description_bn }}</p>
                                  <div v-if="evaluationQuestion.attachment">
                                    <a class="btn btn-sm btn-success mt-2" target='_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + evaluationQuestion.attachment" download><i class="fas fa-download"></i> {{ $t('globalTrans.view_download') }}</a>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row v-if="id">
                                <b-col lg="8" sm="8">
                                  <ValidationProvider name="Marks"  :vid="'marks_' + (questionIndex+1)" rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label-for="'marks_' + (questionIndex+1)"
                                        slot-scope="{ valid, errors }"
                                    >
                                      <template v-slot:label>
                                        {{ $t('elearning_tim.marks') }} <span class="text-danger">*</span>
                                      </template>
                                      <b-form-input
                                          type="number"
                                          :id="'marks_' + (questionIndex+1)"
                                          v-model="formData.course_evaluation_answers[questionIndex].marks"
                                          :state="errors[0] ? false : (valid ? true : null)"
                                          :placeholder="$t('elearning_tim.marks')"
                                          :disabled="evaluationQuestion.question_type === 1"
                                      ></b-form-input>
                                      <div class="invalid-feedback">
                                        {{ errors[0] }}
                                      </div>
                                    </b-form-group>
                                  </ValidationProvider>
                                </b-col>
                              </b-row>
                            </div>

                          </fieldset>
                        </div>
                      </b-row>
                      <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        </div>
                      </div>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseEvaluationStore, courseEvaluationUpdate, courseEvaluationShow, evaluationShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.getFormData()
    }
    if (this.evaluation_id) {
      this.getEvaluationData(this.evaluation_id)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      id: this.$route.query.id,
      evaluation_id: this.$route.query.evaluation_id,
      loading: false,
      formData: {
        id: '',
        tim_evaluation_id: 0,
        exam_date: '',
        course_id: 0,
        training_category_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        status: 1,
        exam_time_start: '',
        exam_time_end: '',
        course_evaluation_answers: []
      },
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      evaluationData: {},
      evaluationObj: {}
    }
  },
  computed: {
    saveBtnName: function () {
      return this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save')
    },
    courseList: function () {
      return this.$store.state.TrainingElearning.commonObj.courseList.filter(item => item.status === 1)
    },
    examTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'Pre Exam' : 'প্রাক পরীক্ষা', value: 1 },
        { text: this.currentLocale === 'en' ? 'Post Exam' : 'পরবর্তী পরীক্ষা', value: 2 }
      ]
    },
    questionTypeList: function () {
      return [
        { text: this.currentLocale === 'en' ? 'MCQ' : 'এমসিকিউ', value: 1 },
        { text: this.currentLocale === 'en' ? 'Written' : 'লিখিত', value: 2 }
      ]
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    },
    trainingCategoryList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
    },
    trainingTitleList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
    },
    currentLocale: function () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    async getFormData () {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, courseEvaluationShow + '/' + this.id)
      if (!result.success) {
        this.formData = []
      } else {
        this.formData = result.data
        this.getEvaluationObj(this.formData.evaluation)
        this.getEvaluationData(this.formData.tim_evaluation_id)
      }
      // this.loading = false
    },
    async getEvaluationData (evaluationId) {
      this.loading = true
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, evaluationShow + '/' + evaluationId)
      if (!result.success) {
        this.formData = {}
      } else {
        this.loading = true
        this.evaluationData = result.data
        this.formData.tim_evaluation_id = this.evaluationData.id
        this.formData.exam_date = this.evaluationData.exam_date
        this.formData.course_id = this.evaluationData.course_id
        this.formData.circular_memo_no = this.evaluationData.circular_memo_no
        this.formData.training_type_id = this.evaluationData.training_type_id
        this.formData.training_category_id = this.evaluationData.training_category_id
        this.formData.training_title_id = this.evaluationData.training_title_id
        this.formData.exam_time_start = this.evaluationData.exam_time_start
        this.formData.exam_time_end = this.evaluationData.exam_time_end
        if (!this.id) {
          this.evaluationData.evaluation_questions.filter(evaliationQuestion => {
            const obj = {
              tim_evaluation_question_id: evaliationQuestion.id,
              mcq_answer: '',
              mcq_answer_multiple: [],
              written_answer: '',
              marks: 0
            }
            this.formData.course_evaluation_answers.push(obj)
          })
        }
        // const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.formData.training_type_id))
        // if (typeof trainingTypeObj !== 'undefined') {
        //   this.evaluationObj.training_type = trainingTypeObj.text_en
        //   this.evaluationObj.training_type_bn = trainingTypeObj.text_bn
        // } else {
        //   this.evaluationObj.training_type = ''
        //   this.evaluationObj.training_type_bn = ''
        // }
        // const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(this.formData.training_category_id))
        // if (typeof trainingCategoryObj !== 'undefined') {
        //   this.evaluationObj.training_category = trainingCategoryObj.text_en
        //   this.evaluationObj.training_category_bn = trainingCategoryObj.text_bn
        // } else {
        //   this.evaluationObj.training_category = ''
        //   this.evaluationObj.training_category_bn = ''
        // }
        // const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.formData.training_title_id))
        // if (typeof trainingTitleObj !== 'undefined') {
        //   this.evaluationObj.training_title = trainingTitleObj.text_en
        //   this.evaluationObj.training_title_bn = trainingTitleObj.text_bn
        // } else {
        //   this.evaluationObj.training_title = ''
        //   this.evaluationObj.training_title_bn = ''
        // }
        // const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.formData.course_id))
        // if (typeof courseObj !== 'undefined') {
        //   this.evaluationObj.course_name = courseObj.text_en
        //   this.evaluationObj.course_name_bn = courseObj.text_bn
        // } else {
        //   this.evaluationObj.course_name = ''
        //   this.evaluationObj.course_name_bn = ''
        // }
        this.getEvaluationObj(this.evaluationData)
        // this.evaluationObj.total_marks = totalMarks
      }
      this.loading = false
    },
    async createData () {
      this.loading = true
      let result = null
      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${courseEvaluationUpdate}/${this.formData.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseEvaluationStore, this.formData)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tim/course-evaluation')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    hasMultipleOptions (evaluationQuestion) {
      if (evaluationQuestion.question_type === 1) {
        const evaluationOptions = evaluationQuestion.evaluation_options.filter(item => item.is_correct === 1)
        return evaluationOptions.length > 1
      }
    },
    getEvaluationObj (data = null) {
      const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.formData.training_type_id))
      if (typeof trainingTypeObj !== 'undefined') {
        this.evaluationObj.training_type = trainingTypeObj.text_en
        this.evaluationObj.training_type_bn = trainingTypeObj.text_bn
      } else {
        this.evaluationObj.training_type = ''
        this.evaluationObj.training_type_bn = ''
      }
      const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(this.formData.training_category_id))
      if (typeof trainingCategoryObj !== 'undefined') {
        this.evaluationObj.training_category = trainingCategoryObj.text_en
        this.evaluationObj.training_category_bn = trainingCategoryObj.text_bn
      } else {
        this.evaluationObj.training_category = ''
        this.evaluationObj.training_category_bn = ''
      }
      const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(this.formData.training_title_id))
      if (typeof trainingTitleObj !== 'undefined') {
        this.evaluationObj.training_title = trainingTitleObj.text_en
        this.evaluationObj.training_title_bn = trainingTitleObj.text_bn
      } else {
        this.evaluationObj.training_title = ''
        this.evaluationObj.training_title_bn = ''
      }
      const courseObj = this.$store.state.TrainingElearning.commonObj.courseList.find(doc => doc.value === parseInt(this.formData.course_id))
      if (typeof courseObj !== 'undefined') {
        this.evaluationObj.course_name = courseObj.text_en
        this.evaluationObj.course_name_bn = courseObj.text_bn
      } else {
        this.evaluationObj.course_name = ''
        this.evaluationObj.course_name_bn = ''
      }
      let totalMarks = 0
      data.evaluation_questions.filter(evaliationQuestion => {
        totalMarks += evaliationQuestion.marks
      })
      this.evaluationObj.total_marks = totalMarks
    }
  }
}
</script>
